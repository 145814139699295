import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Button",
  "type": "Form",
  "icon": "optionsSquareLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Button`}</strong>{` component represents a clickable element on the screen, that is associated with
an action taken by the user. `}<strong parentName="p">{`Button`}</strong>{` is pretty common UX/UI action, so it comes with
different variations and styles.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <p>{`The regular `}<strong parentName="p">{`Button`}</strong>{` comes by default with `}<inlineCode parentName="p">{`primary`}</inlineCode>{` variation and supports `}<inlineCode parentName="p">{`hover`}</inlineCode>{`,
`}<inlineCode parentName="p">{`active`}</inlineCode>{` and `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` HTML states.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true",
        "live": "true",
        "editor": "false",
        "center": "true"
      }}>{`<Button>Button</Button>
`}</code></pre>
    <h1>{`Props`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"primary"`}</inlineCode>{`,`}<inlineCode parentName="td">{`"secondary"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"primary"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The style of the button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode>{`,`}<inlineCode parentName="td">{`"small"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconPosition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left"`}</inlineCode>{`,`}<inlineCode parentName="td">{`"right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position of the icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon rendered on the left or on the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stretch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the button stretched in the container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ElementType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"button"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying root node`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Variants`}</h1>
    <p>{`There are two variants of the button : `}<inlineCode parentName="p">{`primary`}</inlineCode>{` or `}<inlineCode parentName="p">{`secondary`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<>
  <Button>Primary</Button>
  <div style={{ width: "10px" }} />
  <Button variant="secondary">Secondary</Button>
</>
`}</code></pre>
    <h1>{`Sizes`}</h1>
    <p>{`There are two sizes of the button : `}<inlineCode parentName="p">{`medium`}</inlineCode>{` and `}<inlineCode parentName="p">{`small`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<>
  <Button>Medium</Button>
  <div style={{ width: "10px" }} />
  <Button size="small">Small</Button>
</>
`}</code></pre>
    <h1>{`States`}</h1>
    <p>{`Buttons follow the HTML states of `}<inlineCode parentName="p">{`active`}</inlineCode>{`, `}<inlineCode parentName="p">{`hover`}</inlineCode>{` and `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`.`}</p>
    <h2>{`Disabled`}</h2>
    <p>{`Disabled state is a state where the button is un-actionable and user action on it doesn't
produce a result.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<>
  <Button disabled>Primary Disabled</Button>
  <div style={{ width: "10px" }} />
  <Button disabled variant="secondary">
    Secondary Disabled
  </Button>
</>
`}</code></pre>
    <h1>{`Layout`}</h1>
    <p><strong parentName="p">{`Button`}</strong>{` is using the default display properties of a native HTML Button
(`}<inlineCode parentName="p">{`inline-block`}</inlineCode>{`). There are additional layout props that can be used to override
it.`}</p>
    <h2>{`Stretch`}</h2>
    <p>{`Will render the button stretched from one end to the other`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, textAlign: "center" }}>
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Button>Button</Button>
  </div>
  <Divider />
  <div>
    <Button stretch>Button</Button>
  </div>
</div>
`}</code></pre>
    <h1>{`Icons`}</h1>
    <p><strong parentName="p">{`Button`}</strong>{` can also render with a user specified left icon.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div>
  <Button icon={Icons.starFilled}>Button</Button>
  <Separator />
  <Button icon={Icons.starFilled} disabled>
    Button
  </Button>
  <Separator />
  <Button icon={Icons.starFilled} iconPosition="right">
    Button
  </Button>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      